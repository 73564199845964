import * as Sentry from "@sentry/browser";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { getApolloClient } from "./lib/apollo";
import { CurrentUserQuery } from "gql/current_user.graphql";

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    ignoreErrors: [
      "sentry-ignored-error",
      "Unable to preload CSS"
    ],
    initialScope: {
      tags: { "stack.source": "front-end" }
    },
    integrations: [
      ...Sentry.defaultIntegrations,
      new CaptureConsoleIntegration(
        {
          levels: ["error"]
        }
      )]
  });

  getApolloClient()
    .query({ query: CurrentUserQuery })
    .then((res) => {
      Sentry.setUser({
        email: res.data.current_user.email,
        full_name: res.data.current_user.full_name
      });
    })
    .catch((error) => {
      console.error("Error fetching current user:", error);
      Sentry.captureException(error);
    });

  window.addEventListener("unhandledrejection", (event) => {
    console.error("Unhandled promise rejection: ", event.reason);

    let error;
    if (event.reason instanceof Error) {
      error = event.reason;
    } else {
      // Create a new Error to capture the stack trace
      error = new Error("Unhandled promise rejection without error object");
      // Attach additional information
      error.details = event.reason;
    }

    // Send the error to Sentry with additional context
    Sentry.captureException(error, {
      extra: {
        reason: event.reason,
        promise: event.promise
      }
    });
  });
}

import { Dropdown, IconButton, IDropdownOption, TextField } from "office-ui-fabric-react";
import * as React from "react";
import Select from "react-select";
import { GQLTier, GQLTrack } from "../../../schemas/schema";
import { DropdownEnhanced } from "../DropdownEnhanced";
import { TrackParameters } from "../interfaces";
import { numberedOptions } from "@/reports/common";

interface IProps {
  allTracks: GQLTrack[]
  track: TrackDefinition
  allTiers: GQLTier[]
  alterTrack: (opts) => void
  isEmphasis: boolean
  canDelete?: boolean
  isNewReport?: boolean
}

const emphasisOptions = numberedOptions([
  "Low",
  "Medium",
  "High"
]);

export class TrackLine extends React.Component<IProps> {
  tier_weight(tier_number) {
    return parseInt(this.props.allTiers.find((x) => x.id == tier_number)!.default_weight + "");
  }

  handleDelete() {
    if (
      this.props.isNewReport ||
      confirm("Are you sure? You will lose all track information including scored indicators.")
    ) {
      this.alterTrack({ delete: true });
    }
  }

  render() {
    const { allTracks, track, allTiers, isEmphasis } = this.props;
    const trackOptions = allTracks.map((x) => ({ text: x.name, key: x.id })) as IDropdownOption[];
    const weightOptions = allTiers.map((x) => ({ key: x.default_weight, text: x.default_weight + "" }));

    if (isEmphasis) {
      track.weight ||= 1;
      weightOptions.unshift({ key: 1, text: "1" });
    }

    const showTier = !this.props.isEmphasis;
    const defaultNewTrack = value => {
      const out: any = {
        track_id: `${value.key as number}`,
        smes: []
      };
      if (isEmphasis) {
        out.emphasis = allTracks.find((x) => x.id == out.track_id)?.emphasis;
      } else {
        out.tier = 1;
      }
      return out;
    };

    return (
      <>
        <div className="cell">
          <DropdownEnhanced
            data-testid="field-track-select"
            placeholder="Select Track..."
            className="select"
            disabled={!!track.rt}
            onChange={(e, value) => {
              if (e.type == "focus") {
                return;
              }
              !Array.isArray(value) &&
                this.alterTrack(defaultNewTrack(value));
            }}
            options={trackOptions}
            selectedKey={track.track_id ? track.track_id : undefined}
            orderedAlphabetically
          />
        </div>
        <div className="cell">
          {track.track_id && (
            <TextField
              placeholder="Track Focus"
              value={track.focus}
              className="track-focus"
              onChange={(e, focus) => this.alterTrack({ focus })}
              data-testid="field-track-focus"
            />
          )}
        </div>
          {showTier && <div className="cell min-w-8em">
              {track.track_id && (
                  <DropdownEnhanced
                      data-testid="field-tier-select"
                      onChange={(e, v) => {
                        this.alterTrack({ tier: v.key as number, weight: this.tier_weight(v.key as number) });
                      }}
                      options={allTiers.map((x) => ({ key: x.id, text: x.name, value: x.name }))}
                      selectedKey={parseInt(track.tier as string)}
                  />
              )}
          </div>}

              {this.props.isEmphasis && <div className={"cell min-w-8em"}>
                  {track.track_id && (
                    <DropdownEnhanced
                      data-testid="field-emphasis"
                      onChange={(_, value) => {
                        this.alterTrack({ emphasis: parseInt(value?.key as string) });
                      }}
                      options={emphasisOptions}
                      selectedKey={track.emphasis}
                    />
                  )}
          </div>
          }
        <div className="cell min-w-4em">
          {track.track_id && (
            <Dropdown
              data-testid="field-track-weight"
              onChange={(e, v) => {
                this.alterTrack({ weight: parseInt(v?.key as string) });
              }}
              options={weightOptions}
              selectedKey={track.weight}
            />
          )}
        </div>
        <div className="cell" data-testid="cell-add-ons">
          {track.track_id && this.hasAddons(this.getTrack(track.track_id)) && (
            <Select
              className="add-ons"
              classNamePrefix="add-ons"
              isMulti
              placeholder="Choose from here"
              onChange={(value) => {
                if (!value || Array.isArray(value)) {
                  const newValues = value || [];
                  this.alterTrack({ add_ons: newValues.map((x) => x.value + "") });
                }
              }}
              options={this.addon_select_for_track(track.track_id)}
              value={this.addon_select_for_track(track.track_id).filter((x) => (track.add_ons || []).includes(x.value))}
            />
          )}
        </div>
        <div className="cell min-w-32px">
          {this.props.canDelete && (
            <IconButton
              className="btn-delete"
              iconProps={{ iconName: "trash" }}
              onClick={() => this.handleDelete()}
              data-testid="delete-track"
            />
          )}
        </div>
      </>
    );
  }

  private alterTrack(opts: TrackParameters) {
    this.props.alterTrack(opts);
  }

  private hasAddons(track: GQLTrack) {
    return track.addon_tags!.length > 0;
  }

  getTrack(track_id: ID) {
    return this.props.allTracks.find((x) => x.id == track_id)!;
  }

  private addon_select_for_track(track_id: ID) {
    const track = this.getTrack(track_id);
    return track.addon_tags!.map((x) => ({ label: x.name!, value: x.id }));
  }

  private isCompliance(track: TrackDefinition) {
    return this.getTrack(track.track_id).name == "Compliance";
  }
}

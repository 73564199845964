import React from "react";
import { forceCheck } from "react-lazyload";
import BaseComponent from "../BaseComponent";
import { debounce } from "../common";
import LockedPaths from "../wrappers/LockedPaths";

export class LocalContext {
  public static ReactContext = React.createContext({});
  public static Provider = LocalContext.ReactContext.Provider;
  public static Consumer = LocalContext.ReactContext.Consumer;
  private readonly component: BaseComponent;

  private _leftTabDefault = "Questions";
  get leftTabDefault() {
    return this._leftTabDefault;
  }

  set leftTabDefault(value: string) {
    if (this._leftTabDefault == value) {
      return;
    }
    this._leftTabDefault = value;
    PubSub.publish("context.leftTabDefault", [value]);
  }

  private _reportTrackMode: string | null = null;

  get reportTrackMode() {
    return this._reportTrackMode;
  }

  set reportTrackMode(value) {
    if (this._leftTabDefault == value) {
      return;
    }

    this._reportTrackMode = value;
  }

  show_key_recommendations = false;
  show_key_strengths = false;
  show_track_summary = false;
  show_findings = false;
  show_recommendations = false;
  show_track_score_context = true;
  show_tech_stack = false;
  show_tech_debt = false;
  show_key_metrics = false;
  show_value_creation = false;
  show_data_collection = false;
  show_tech_indicators = true;
  locked_paths: LockedPaths;
  public enabled_views: Set<string> = new Set<string>();
  topics_in_view: string[] = [];
  report_indicators_filters = {} as any;

  public allHeaders = [
    "show_track_summary",
    "show_key_strengths",
    "show_key_recommendations",
    "show_tech_debt",
    "show_track_score_context",
    "show_key_metrics",
    "show_tech_stack",
    "show_value_creation",
    "show_findings",
    "show_recommendations"
  ];

  constructor(component: any, sub: (ActionCable.Channel | undefined) = undefined) {
    this.component = component;
    if (sub) {
      this.locked_paths = new LockedPaths(sub);
    }
  }

  selectAllHeaderData() {
    this.allHeaders.map((x) => this.set(x, true));
  }

  unselectAllHeaderData() {
    this.allHeaders.map((x) => this.set(x, false));
  }

  init() {
    this.component.setState({ localContext: this });
  }

  updateComponent() {
    this.component.setState({ localContext: this });
    this.component.forceUpdate();
  }

  set(key: string, value: any) {
    this[key] = value;
    this.updateComponent();
    PubSub.publish("context.update", [key]);
    setTimeout(() => forceCheck(), 0);
  }

  reverse(key: string) {
    this.set(key, !this[key]);
  }

  publishView = () => {
    PubSub.publish("context.update", ["view"]);
  };

  enableView(view: string) {
    if (this.enabled_views.add(view)) {
      this.updateComponent();
      debounce(this.publishView);
    }
  }

  disableView(view: string) {
    if (this.enabled_views.delete(view)) {
      this.updateComponent();
      debounce(this.publishView);
    }
  }

  showView(view: string): boolean {
    return this.enabled_views.has(view);
  }

  toggleView(view: string): void {
    if (this.enabled_views.has(view)) {
      this.disableView(view);
    } else {
      this.enableView(view);
    }
  }

  disableAllViews(update = true) {
    this.enabled_views.clear();
    if (update) {
      this.updateComponent();
    }
  }

  updateReportIndicatorFilters(changes: any) {
    for (const key in changes) {
      this.report_indicators_filters[key] = (this.report_indicators_filters[key] || 0) + changes[key];
    }
  }

  get sub() {
    // @ts-expect-error
    return this.component.sub;
  }

  sendMessage(msg, type = null as string | null) {
    if (type) {
      msg._msgType = type;
    }
    // @ts-expect-error
    this.component.sub.send(msg);
  }
}

import * as Sentry from "@sentry/browser";
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUUID } from "../common/utils";
import { SubscriptionLink } from "./ws/link";
import * as constants from "@/common/constants";
import { FollowUpTypePolicies } from "@/lib/typePolicies/followUps";

function ignoreError() {
  throw new Error("sentry-ignored-error");
}

function isLoginExemptPath(path: string) {
  const exemptPaths = [
    "/users/sign_in",
    "/clients/auth",
    "/users/confirmation",
    "/users/password/edit"
  ];

  if (exemptPaths.some((exemptPath) => path.startsWith(exemptPath))) {
    return true;
  }
  return false;
}

export const SentryWrapper = {
  message: (message: string) => {
    constants.Sentry.enabled && Sentry.captureMessage(message);
  }
};

const messageShouldBeIgnored = (message: string) => {
  const ignoredMessages = [
    "not_found"
  ];
  return ignoredMessages.some((ignoredMessage) => message.match(ignoredMessage));
};

const errorLink = onError(({ graphQLErrors, operation, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions, path }) => {
      if (extensions?.error_type === "json_error") { return; }
      if (messageShouldBeIgnored(message)) { return; }
      Sentry.withScope((scope) => {
        // set extras for your event
        scope.setExtras({
          graphql: {
            operationName: operation.operationName,
            variables: operation.variables,
            query: operation.query.loc?.source.body,
            error: message
          }
        });

        message && SentryWrapper.message(`GraphQL Error: ${message}`);
      });
    }
    );
  }

  if (networkError) {
    // @ts-expect-error
    if (networkError.statusCode === 401) {
      if (!isLoginExemptPath(window.location.pathname)) {
        setTimeout(() => {
          window.location.href = "/users/sign_in";
        }, 0);
      };
      return ignoreError();
    }

    // @ts-expect-error
    else if (networkError.statusCode >= 400) {
      // @ts-expect-error
      networkError.message = `${networkError.response.statusText}: ${operation.operationName} with vars: ${JSON.stringify(operation.variables)}`;
      // Add some context to it and let Sentry handle it
    }

    // @ts-expect-error
    else if (networkError.statusCode === 500) {
      // This will be handled by Sentry on the server side.
      return ignoreError();
    } else {
      // This is a client's connection error
      return ignoreError(); // And we don't want that on Sentry
    }
  }
});

export function getApolloCache() {
  return new InMemoryCache({
    addTypename: true,
    typePolicies: {
      CurrentUserPayload: {
        fields: {
          show_prompts: {
            merge(existing, incoming) {
              return incoming;
            }
          }
        }
      },
      ...FollowUpTypePolicies
    }
  });
}

export function getApolloClient() {
  window.fingerprint = window.fingerprint || createUUID();
  let csrfToken = "";
  try {
    csrfToken = document.querySelector("meta[name=csrf-token]")!
      .getAttribute("content") as string;
  } catch (e) {}
  const httpLink = createHttpLink({
    credentials: "same-origin",
    uri: "/graphql",
    headers: {
      "X-CSRF-Token": csrfToken,
      "X-Fingerprint": window.fingerprint,
      "X-Requested-With": "XMLHttpRequest"
    }
  });

  // https://www.apollographql.com/docs/link/composition/
  const link = ApolloLink.from([
    // new ActionCableLink({cable}),
    errorLink,
    new SubscriptionLink(),
    httpLink
  ]);

  const cache = getApolloCache();
  window.apolloCache = cache;

  const resolvers = {};

  return new ApolloClient({
    link, cache, resolvers, connectToDevTools: (window.location.hostname == "localhost")
  });
}

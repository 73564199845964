import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/plugins/char_counter.min.css";

import "froala-editor/js/plugins/word_paste.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/inline_style.min.js";

// beyondgrammar plugin needs this hack
import "./define_froala";
import "../../../vendor/beyond-grammar-plugin";

import FroalaEditorComponent from "react-froala-wysiwyg";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "../Modal";
import { faUpRightAndDownLeftFromCenter } from "@fortawesome/pro-solid-svg-icons";
import { getMaxLengthMesssage, getRequiredMessage } from "../../utils/validators";
import { Label } from "office-ui-fabric-react";
import { useCharCount } from "./hooks/useCharCount";

window.FroalaEditor.RegisterShortcut(190, "formatUL", null, ".", false, false);
window.FroalaEditor.RegisterShortcut(191, "formatOL", null, "/", false, false);

const isIconVisible = (ele, container, gap): boolean => {
  const { bottom } = ele.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return bottom - gap >= containerRect.top && bottom <= containerRect.bottom;
};

const FROALA_KEY = import.meta.env.VITE_FROALA_KEY || "UBB7jD5G4H5F4F3B8B7bHIMFI1EWBXIJe1BZLZFd1d1MXQLjC10D7D5A4B2B3D3E2A2A5==";

const FROALA_BASE_CONFIG = {
  key: FROALA_KEY,
  htmlRemoveTags: ["iframe", "script", "style"],
  attribution: false
};

interface IFroalaBaseProps {
  model: string
  config: object
  onModelChange: (val: string) => void
  className?: string
  toolbarTitle?: string
  dataTestId?: string
  required?: boolean
  label?: string
  disabled?: boolean
}

export const FroalaEditorBase = (
  {
    model,
    config,
    onModelChange,
    className,
    toolbarTitle,
    dataTestId,
    required,
    label,
    disabled
  }: IFroalaBaseProps): JSX.Element => {
  const editorRef = useRef<any>();
  const containerRef = useRef<any>();
  const iconRef = useRef<any>();

  const [editorId] = useState(uuid());

  const [currentImageContainer, setCurrentImageContainer] = useState<HTMLElement>();
  const [imageLeaveTimeoutId, setImageLeaveTimeoutId] = useState<any>();
  const [isHoverIcon, setIsHoverIcon] = useState(false);
  const [isHoverImage, setIsHoverImage] = useState(false);
  const [imageContainerRect, setImageContainerRect] = useState<DOMRect>();
  const [showImageModal, setShowImageModal] = useState(false);
  const { characterLimit, characterLimitAlgo, onCharLimitExceeded } = config as any;

  const {
    charLimitEnabled,
    charCountConfig,
    charCount,
    isCharLimitExceeded
  } = useCharCount(config, editorRef, characterLimit, characterLimitAlgo);

  useEffect(() => {
    $(containerRef.current).on("mouseenter", ".fr-view img", (e) => {
      clearTimeout(imageLeaveTimeoutId);
      setCurrentImageContainer(e.target);
      setImageContainerRect(e.target.getBoundingClientRect());
      setIsHoverImage(true);
    });
    $(containerRef.current).on("mouseleave", ".fr-view img", () => {
      const timeoutId = setTimeout(() => {
        setIsHoverImage(false);
      }, 100);
      setImageLeaveTimeoutId(timeoutId);
    });
  }, [containerRef.current]);

  useEffect(() => {
    const handleScroll = (): void => {
      if (iconRef?.current != null && currentImageContainer != null) {
        setImageContainerRect(currentImageContainer.getBoundingClientRect());
      }
    };

    const editorWrapper = $(containerRef.current).find("div.fr-wrapper").get(0);
    editorWrapper?.addEventListener("scroll", handleScroll);

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
      editorWrapper?.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef?.current, iconRef?.current]);

  const toolbarConfig = {
    toolbarContainer: `#toolbarContainer-${editorId}`
  };

  const editorConfig = {
    ...FROALA_BASE_CONFIG,
    ...config,
    ...(toolbarTitle ? toolbarConfig : {}),
    ...charCountConfig
  };

  const renderToolbarContainer = useCallback(() => {
    if (toolbarTitle) {
      return (
        <div className="toolbar-title-container">
          <div className="toolbar-title"><strong>{toolbarTitle}</strong></div>
          <div className="toolbar-toolbar" id={`toolbarContainer-${editorId}`}></div>
        </div>
      );
    } else {
      return null;
    }
  }, [toolbarTitle]);

  const renderExpandIcon = useCallback(() => {
    if (currentImageContainer != null && imageContainerRect != null && (isHoverIcon || isHoverImage)) {
      const rect = imageContainerRect;
      const editorWrapper = $(containerRef.current).find("div.fr-wrapper").get(0);
      const visible = isIconVisible(currentImageContainer, editorWrapper, 46);

      const parentRect = editorWrapper.getBoundingClientRect();

      return visible && (
        <div className={"hovered-image-icon"}
             ref={iconRef}
             style={{ bottom: parentRect.bottom - rect.bottom + 14, right: parentRect.right - rect.right + 14 }}
             onClick={() => setShowImageModal(true)}
             onMouseEnter={() => setIsHoverIcon(true)}
             onMouseLeave={() => setIsHoverIcon(false)}
             data-testid="preview-image-button"
        >
          <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} size={"sm"} />
        </div>
      );
    } else {
      return null;
    }
  }, [currentImageContainer, imageContainerRect, isHoverImage, isHoverIcon]);

  const renderImagePreviewModal = (): React.ReactNode => {
    return (
      <Modal title="" onClose={() => setShowImageModal(false)}>
        <div className="froala-editor-image-preview" data-testid="froala-editor-image-preview">
          {currentImageContainer != null &&
            <img
              src={currentImageContainer.getAttribute("src") ?? ""}
              alt={currentImageContainer.getAttribute("alt") ?? ""}
            />
          }
        </div>
      </Modal>
    );
  };

  const errorMessages = {
    required: getRequiredMessage(label),
    limitExceeded: getMaxLengthMesssage(label, characterLimit)
  };

  const getErrorMessage = useCallback(() => {
    let errorName = "";
    if (required && charCount == 0) {
      errorName = "required";
    } else if (isCharLimitExceeded()) {
      errorName = "limitExceeded";
    }
    return errorMessages[errorName] || "";
  }, [required, charCount, isCharLimitExceeded]);

  useEffect(() => {
    if (!onCharLimitExceeded) return;
    onCharLimitExceeded(isCharLimitExceeded());
  }, [charCount]);

  const errorMessage = getErrorMessage();
  const limitExceeded = isCharLimitExceeded();
  const hasError = limitExceeded || errorMessage;

  const renderLabel = useCallback(() => {
    return toolbarTitle == null && label != null && <Label required={required} disabled={disabled}>{label}</Label>;
  }, [label, required, disabled]);

  return (
    <div className={cn("froala-editor", className, { error: hasError })} data-testid={dataTestId} ref={containerRef}>
      {renderToolbarContainer()}
      {renderLabel()}
      <FroalaEditorComponent model={model} config={editorConfig} onModelChange={onModelChange} ref={editorRef}/>
      {renderExpandIcon()}
      <div className="flex justify-between items-center my-4" >
        { hasError && <span className={cn({ "not-valid text-darkred": hasError })} data-testid="error-message">
          {errorMessage}
        </span>}
        {charLimitEnabled &&
          <span className={cn("custom-counter", { "not-valid text-darkred": limitExceeded })} data-testid="char-counter">
            {`${charCount}/${characterLimit}`}
          </span>
        }
      </div>
      {showImageModal && renderImagePreviewModal()}
    </div>
  );
};

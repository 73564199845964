import React from "react";
import { GQLReport, GQLUser } from "../../../schemas/schema";

interface IProps {
  report: GQLReport
  users: GQLUser[]
}

export class RolesFeedback extends React.Component<IProps, {}> {
  render() {
    const { report, users } = this.props;
    let text = [
      ...new Set(
        report
          .roles!.map((role) => users.find((user) => user.id == role.user.id))
          .filter((user) => !user?.profile_image_url && user?.full_name)
          .map((user) => user?.full_name)
      )
    ].join(", ");

    text = text ? "Missing Photo(s): " + text : "";
    return text && <p className={"roles-feedback"}>{text}</p>;
  }
}
